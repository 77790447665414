import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <h2>Maintenance Break</h2>
        <p>Our website is undergoing some fine-tuning.<br /> We'll be back soon!</p>
      </header>
    </div>
  );
}

export default App;
